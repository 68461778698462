import axios from "axios";
import store from "@/store/root";
import { API_URL } from "@/constants";
import { signOut } from "@/store/authSlice";

export const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState();

    if (typeof window !== "undefined") {
      const accessToken = state?.auth.token;

      if (accessToken) {
        config.headers["Authorization"] = `${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.response?.status === 401) {
      store.dispatch(signOut());
    }
    return err;
  }
);
