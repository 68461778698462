"use client";

import { api } from "@/services/http/api";
import { MediaType } from "@/types/media";

const useUploader = (uploadUrl: string = "upload-center/upload") => {
  async function upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const res = await api.post(uploadUrl, formData);
    return res.data as MediaType;
  }

  return { upload };
};

export default useUploader;
