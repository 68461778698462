import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import authSlice from "./authSlice";
import settingsSlice from "./settingsSlice";
import postEditorSlice from "./postEditor";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "admin-panel",
  storage: storage,
};

const rootReducer = combineReducers({
  auth: authSlice,
  settings: settingsSlice,
  postEditor: postEditorSlice,
});

const _persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: _persistedReducer,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
