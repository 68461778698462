import { generateId } from "@/utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ContentBlockType {
  Text = "text",
  Image = "image",
  Gallery = "gallery",
  Video = "video",
  Headline1 = "headline1",
  Headline2 = "headline2",
  BulletedList = "bulletedList",
  NumberedList = "numberedList",
  TodoList = "todoList",
  DividerLine = "dividerLine",
}

export type ContentBlock = {
  type: ContentBlockType;
  body?: any;
  id: string;
};

export type PostEditorState = {
  blocks: ContentBlock[];
};

const initialState: PostEditorState = {
  blocks: [],
};

const postEditorSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setBulkBlocks: (state, action: PayloadAction<ContentBlock[]>) => {
      state.blocks = action.payload;
    },
    addBlock: (state, action: PayloadAction<ContentBlockType>) => {
      state.blocks = [
        ...state.blocks,
        {
          id: generateId(),
          type: action.payload,
        },
      ];
    },
    editBlockData: (
      state,
      action: PayloadAction<{
        id: string;
        body: any;
      }>
    ) => {
      let blocks = [...state.blocks];
      const findBlockIndex = blocks.findIndex(
        (item) => item.id === action.payload.id
      );
      blocks[findBlockIndex]["body"] = action.payload.body;
      state.blocks = blocks;
    },
    editBlockType: (
      state,
      action: PayloadAction<{
        id: string;
        type: ContentBlockType;
      }>
    ) => {
      let blocks = [...state.blocks];
      const findBlockIndex = blocks.findIndex(
        (item) => item.id === action.payload.id
      );
      blocks[findBlockIndex]["type"] = action.payload.type;
      blocks[findBlockIndex]["body"] = "";
      state.blocks = blocks;
    },
    changeOrder: (
      state,
      action: PayloadAction<{ oldIndex: number; newIndex: number }>
    ) => {
      let removedSourceTask = state.blocks.splice(
        action.payload.oldIndex,
        1
      )?.[0];

      state.blocks.splice(action.payload.newIndex, 0, removedSourceTask);
    },
  },
});

export const {
  addBlock,
  editBlockType,
  editBlockData,
  changeOrder,
  setBulkBlocks,
} = postEditorSlice.actions;

export default postEditorSlice.reducer;
