"use client";

import React, { ReactNode, useState } from "react";

import { MediaType } from "@/types/media";
import { useUploader } from "@/hooks";

type Props = {
  children: (upload?: any) => ReactNode;
  onUpload?: (m: MediaType) => void;
};

export default function UploaderWrapper({ children, onUpload }: Props) {
  const [isUploading, setIsUploading] = useState(false);

  const { upload } = useUploader();

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;

    setIsUploading(true);

    const media = await upload(e.target.files[0]);

    setIsUploading(false);

    if (onUpload) onUpload(media);
  };

  let className = "cursor-pointer";
  if (isUploading) className = "opacity-50 pointer-events-none";

  return (
    <div className={`relative ${className}`}>
      <input
        type="file"
        className="absolute cursor-pointer opacity-0 top-0 left-0 w-full h-full z-10"
        onChange={handleUpload}
      />
      {children(upload)}
    </div>
  );
}
