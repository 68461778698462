import { Navigate } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { Routes } from ".";
import { useAuth } from "@/hooks";
import { useAppDispatch } from "@/store/root";
import { getProfileThunk } from "@/store/authSlice";

type Props = {
  element: ReactNode;
};

export default function PrivateRoute({ element }: Props) {
  const { isLoggedIn } = useAuth();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProfileThunk());
  }, []);

  if (!isLoggedIn)
    return <Navigate to={`${Routes.auth.index}/${Routes.auth.login}`} />;

  return <>{element}</>;
}
